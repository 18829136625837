/* Images will never be bigger then a tile */
.mosaic-tile img {
    max-width: 100%;
    height: auto;
}
/*
  prevent margin-top for first heading element's,
  to have the grid-cells starting at the same horizontal level
*/
.mosaic-tile-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &:first-child {
            margin-top: 0;
        }
    }
    #section-related,
    #section-category {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.mosaic-tile-align-center {
    text-align: center;
}

.mosaic-tile-align-left {
    float: left;
    z-index: 200;
}

.mosaic-tile-align-right {
    float: right;
    z-index: 200;
}

.mosaic-tile-padding-right {
    padding-right: 1em;
}

.mosaic-tile-padding-bottom {
    padding-bottom: 1ex;
}

.mosaic-grid-row-dark {
    background: #ccc;
    padding: 1ex 1em;
    color: #2c2c2c;

    & + .mosaic-grid-row {
        padding-top: 1ex;
    }

    & + .mosaic-grid-row-dark {
        padding-top: 0;
    }
}

.mosaic-tile #commenting {
    font-size: 100%;
}

.mosaic-tile .comment {
    font-size: 100%;
}

#content .mosaic-tile .discussion div.documentByLine {
    font-size: 100%;
}
